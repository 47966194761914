body {
  font-family: Arial, sans-serif;
  background-color: #f2f2f2;
}

.main {
  background-color: #f2f2f2;
}

.container {
  background-color: #fdfdfdeb;
  border-radius: 4px;
  box-shadow: 0 0 60px rgba(69, 56, 56, 0.5);
  padding: 60px;
  width: 400px;
  margin: 50px auto;
}

.generated-password {
  text-align: center;
}

h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #000000;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #080808;
  font-weight: bold;
}

input[type="number"],
input[type="checkbox"] {
  margin-right: 5px;
}

button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #0b0b0c;
  color: #fff;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.5s ease;
}

button:hover {
  background-color: rgb(85, 86, 90);
}

p {
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #000000;
}

.password-box {
  background-color: #f2f2f2;
  border-radius: 5px;
  padding: 15px;
  margin-top: 20px;
}

.password-box p {
  margin: 0;
  font-size: 20px;
  text-align: center;
  word-break: break-all;
}

.powered-by {
  margin-top: 20px;
  text-align: center;
  font-size: 12px;
}

.powered-by a {
  color: #20272f;
  text-decoration: none;
}

.powered-by a:hover {
  text-decoration: underline;
}

/* Add space between the buttons */
button + button {
  margin-left: 30px;
}

.marquee {
  white-space: nowrap;
  overflow: hidden;
  animation: marquee-animation 20s linear infinite;
  font-size: 22px;
  margin-top: 20px
}

@keyframes marquee-animation {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}